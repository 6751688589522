import React from 'react'
import { connect } from 'react-redux'

import pdfToJpg from '../utils/pdf-jpg'

const DynoDownload = props => {
  const { pdfKey, _id } = props.dyno.current
  return (
    <>
      <a
        className="btn btn-secondary mr-2"
        href={pdfKey}
        download={`${pdfKey}.pdf`}
      >
        Download dyno PDF
      </a>
      <button
        className="btn btn-secondary"
        onClick={() => pdfToJpg(pdfKey, _id)}
      >
        Download dyno JPG
      </button>
    </>
  )
}

const mapStateToProps = ({ dyno }) => ({ dyno })

export default connect(
  mapStateToProps,
  null
)(DynoDownload)
