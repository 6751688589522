import React from 'react'
import { Link } from 'react-router-dom'

import examplePDF from '../assets/pdf/example.pdf'
import exampleJPG from '../assets/jpg/example.jpeg'
import SiteTemplate from '../templates/Site'
import tgtLogo from '../assets/logos/tgt-logo.png'

const LandingScreen = () => {
  return (
    <SiteTemplate>
      <div className="text-center hero px-3">
        <h1>Welcome to DynoGen</h1>
        <p>
          The first and only automated dyno for tuners and remapping
          specialists.
        </p>
      </div>
      <div className="container py-8">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            <h2>Created within seconds, loved by customers!</h2>
            <p>
              DynoGen reports are created instantly. Simply enter a vehicle
              registration and we'll create a report that shows the power (BHP)
              and torque (Nm) gains in a professional looking dyno chart.
            </p>
          </div>
        </div>
        <div className="row mt-6 justify-content-center">
          <div className="col-12 col-sm-3 mt-4 text-center">
            <a
              href={examplePDF}
              download="example.pdf"
              className="btn btn-secondary"
            >
              Download example PDF
            </a>
          </div>
          <div className="col-12 col-sm-4 mt-4 text-center">
            <a
              href={exampleJPG}
              download="example.jpeg"
              className="btn btn-secondary"
            >
              Download example JPEG
            </a>
          </div>
        </div>
      </div>
      <div className="bg-light pb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 py-8 text-center">
              <h2>Easy and simple pricing</h2>
              <p>
                Choose to pay for DynoGen monthly to assist your cashflow, or
                pay annually to receive a massive discount!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="card">
                <div className="card-body text-center">
                  <div className="card-category">Monthly</div>
                  <div className="display-4 my-4">£14.99</div>
                  <ul className="list-unstyled leading-loose">
                    <li>
                      <b>Unlimited</b> report generation
                    </li>
                    <li>
                      <b>Unlimited</b> report storage
                    </li>
                    <li>Your company branding</li>
                    <li>Vehicle registration lookup</li>
                    <li>Export to PDF and JPEG</li>
                    <li>Instant turnaround</li>
                    <li>No saving</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="card">
                <div className="card-status bg-primary" />
                <div className="card-body text-center">
                  <div className="card-category">Annual</div>
                  <div className="display-4 my-4">£129.99</div>
                  <ul className="list-unstyled leading-loose">
                    <li>
                      <b>Unlimited</b> report generation
                    </li>
                    <li>
                      <b>Unlimited</b> report storage
                    </li>
                    <li>Your company branding</li>
                    <li>Vehicle registration lookup</li>
                    <li>Export to PDF and JPEG</li>
                    <li>Instant turnaround</li>
                    <li className="text-primary">
                      <b>Save over 27%!</b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-8">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            <h2>Boost business and profit with DynoGen</h2>
            <p>
              Use DynoGen to create an new income stream or to boost
              recommendations and referrals.
            </p>
          </div>
        </div>
        <div className="row mt-6">
          <div className="col text-center">
            <Link to="/setup" className="btn btn-primary">
              START YOUR 14 DAY FREE TRIAL TODAY
            </Link>
          </div>
        </div>
      </div>
      <div className="border-top text-left py-6 px-6 container">
        <div className="row justify-content-sm-between">
          <div className="col col-12 col-sm-6">
            <h3 className="h4">DynoGen</h3>
            <p className="m-0">Unit 6</p>
            <p className="m-0">Planetary Industrial Estate</p>
            <p className="m-0">Willenhall</p>
            <p className="m-0">West Midlands</p>
            <p className="m-0">WV13 3XA</p>
          </div>
          <div className="col col-12 col-sm-6 row flex-column align-content-sm-end justify-content-center mt-4 mt-sm-0">
            <img
              className=""
              width="199"
              height="72"
              src={tgtLogo}
              alt="Top Gear Tuning logo"
            />
            <p className="m-0 mt-2">
              Powered by{' '}
              <a href="https://topgear-tuning.com" target="_blank">
                TopGear Tuning
              </a>
            </p>
          </div>
        </div>
      </div>
    </SiteTemplate>
  )
}

export default LandingScreen
