import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'
import cn from 'classnames'

import Alert from '../components/common/Alert'
import AuthTemplate from '../templates/Auth'
import { updateAuth, sendResetPasswordEmail } from '../actions/auth'

class ForgotPasswordScreen extends Component {
  constructor(props) {
    super(props)
    this.state = { email: '', emailValid: false }
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillUnmount() {
    this.props.updateAuth({ error: null })
  }

  handleEmailChange(e) {
    const email = e.target.value
    const emailValid = isEmail(email)
    this.setState({ email, emailValid })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { email, emailValid } = this.state
    if (!emailValid) {
      this.props.updateAuth({ error: 'Please enter a valid email address' })
    } else {
      this.props.sendResetPasswordEmail({ email })
    }
  }

  render() {
    const { auth } = this.props
    if (auth.resetPasswordEmailSent) {
      return <Redirect to="/reset-password-email-sent" />
    } else {
      return (
        <AuthTemplate>
          <h1 className="text-center font-weight-light h3 pb-3">
            Reset your password
          </h1>
          <Alert
            error={auth.error}
            onDismissError={() => this.props.updateAuth({ error: null })}
          />
          <form className="card mb-4" onSubmit={this.handleSubmit}>
            <div className="card-body p-5">
              <p className="font-weight-bold">
                Enter your email address and we will send you a link to reset
                your password.
              </p>
              <div className="form-group">
                <label className="form-label">Email address</label>
                <div className="input-icon">
                  <input
                    type="text"
                    placeholder="Enter email"
                    autoComplete="email"
                    autoFocus
                    autoCorrect="off"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-footer">
                <button
                  type="submit"
                  className={cn('btn', 'btn-block', 'btn-primary', {
                    'btn-loading': auth.loading
                  })}
                >
                  Send reset password email
                </button>
              </div>
            </div>
          </form>
        </AuthTemplate>
      )
    }
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth }
}

export default connect(
  mapStateToProps,
  { updateAuth, sendResetPasswordEmail }
)(ForgotPasswordScreen)
