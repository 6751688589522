import React, { Component } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'

import MainTemplate from '../templates/Main'
import Alert from '../components/common/Alert'
import { updateSettings } from '../actions/auth'

class SettingsScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      preferredPower: props.auth.settings.preferredPower,
      preferredTorque: props.auth.settings.preferredTorque,
      loading: false,
      error: null,
      success: null
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.loading !== this.props.auth.loading) {
      if (this.state.loading) {
        this.setState({ loading: false })
      }

      if (!this.props.auth.error) {
        this.setState({ success: true })
      } else {
        this.setState({ error: this.props.auth.error })
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    const { preferredPower, preferredTorque } = this.state
    this.setState({ loading: true, error: null, success: null })
    this.props.updateSettings({ preferredPower, preferredTorque })
  }

  render() {
    const btnCn = cn({
      btn: true,
      'btn-primary': true,
      'btn-loading': this.state.loading
    })

    return (
      <MainTemplate>
        <h1>Settings</h1>
        {this.state.success && (
          <Alert
            success="Your settings have been saved."
            onDismissSuccess={() => this.setState({ success: null })}
          />
        )}
        {this.state.error && (
          <Alert
            error={this.state.error}
            onDismissError={() => this.setState({ error: null })}
          />
        )}
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="form-group">
                <label htmlFor="powerUnit">Power unit</label>
                <select
                  className="custom-select"
                  id="powerUnit"
                  disabled={this.state.loading}
                  onChange={e =>
                    this.setState({ preferredPower: e.target.value })
                  }
                  value={this.state.preferredPower}
                >
                  <option key="bhp" value="bhp">
                    BHP
                  </option>
                  <option key="ps" value="ps">
                    PS
                  </option>
                  <option key="kw" value="kw">
                    Kw
                  </option>
                </select>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="form-group">
                <label htmlFor="torqueUnit">Torque unit</label>
                <select
                  className="custom-select"
                  id="torqueUnit"
                  disabled={this.state.loading}
                  onChange={e =>
                    this.setState({ preferredTorque: e.target.value })
                  }
                  value={this.state.preferredTorque}
                >
                  <option key="nm" value="nm">
                    Nm
                  </option>
                  <option key="ibft" value="ibft">
                    Ib/ft
                  </option>
                </select>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className={btnCn}
            disabled={this.state.loading || this.state.success}
          >
            Save settings
          </button>
        </form>
      </MainTemplate>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })

export default connect(
  mapStateToProps,
  { updateSettings }
)(SettingsScreen)
