import React from 'react'

const FullScreenLoading = () => {
  return (
    <div className="page">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col col-md-10 col-lg-8 col-xl-6 mx-auto">
              <span className="loading p-6"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullScreenLoading
