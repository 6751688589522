import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

class SiteNav extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const navStyles = cn({
      'd-sm-block': true,
      collapse: true,
      show: this.state.isOpen
    })

    return (
      <div className="d-flex ml-auto">
        <button
          className="btn btn-link border-0 header-toggler d-flex d-sm-none clickable order-2"
          onClick={this.toggle}
        >
          <span className="header-toggler-icon" />
        </button>
        <div className={navStyles}>
          <Link to="/login" className="btn btn-secondary mx-1">
            Login
          </Link>
          <p className="d-sm-none my-4 text-center font-weight-bold">OR</p>
          <Link to="/setup" className="btn btn-primary mx-1">
            Start 14 day free trial
          </Link>
        </div>
      </div>
    )
  }
}

export default SiteNav
