import React from 'react'
import { StripeProvider } from 'react-stripe-elements'

import { STRIPE_KEY } from '../../config'
import StripeElements from './StripeElements'

const Stripe = props => {
  return (
    <StripeProvider apiKey={STRIPE_KEY}>
      <StripeElements {...props} />
    </StripeProvider>
  )
}

export default Stripe
