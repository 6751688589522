import React from 'react'
import { connect } from 'react-redux'

const ManualResults = ({ vehicle, auth }) => {
  const { current } = vehicle
  const { preferredPower, preferredTorque } = auth.settings
  const isDiesel = current.fuel.toLowerCase().includes('diesel')

  const units = {
    bhp: 'BHP',
    kw: 'Kw',
    ps: 'PS',
    nm: 'Nm',
    ibft: 'Ib/ft'
  }

  const power = current.power[preferredPower]
  const powerUnit = units[preferredPower]
  const torque = current.torque[preferredTorque]
  const torqueUnit = units[preferredTorque]

  return (
    <div className="mt-4 mt-lg-0">
      <div className="bg-white p-5 border rounded">
        <h2 className="h4 border-bottom pb-2 mb-2">Vehicle details</h2>
        <div className="row">
          <div className="col-12">
            <p className="mb-1">
              Type:
              <span className="float-right font-weight-bold">
                {current.type}
              </span>
            </p>
            <p className="mb-1">
              Make:
              <span className="float-right font-weight-bold">
                {current.make}
              </span>
            </p>
            <p className="mb-1">
              Range:
              <span className="float-right font-weight-bold">
                {current.range}
              </span>
            </p>
            <p className="mb-1">
              Fuel:
              <span className="float-right font-weight-bold">
                {current.fuel}
              </span>
            </p>
            <p className="mb-1">
              Model:
              <span className="float-right font-weight-bold">
                {current.model}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white p-5 border rounded mt-4">
        <h2 className="h4 border-bottom pb-2 mb-2">Performance details</h2>
        <div className="row">
          <div className="col-6">
            <p className="font-weight-bold mb-2">Power</p>
            <p className="mb-1">
              Stock:
              <span className="float-right font-weight-bold">
                {power.stock} {powerUnit}
              </span>
            </p>
            <p className="mb-1">
              Stage one {isDiesel ? '(basic)' : ''}:
              <span className="float-right font-weight-bold">
                {power.stageOneBasic} {powerUnit}
              </span>
            </p>
            {isDiesel && (
              <p className="mb-1">
                Stage one (performance):
                <span className="float-right font-weight-bold">
                  {power.stageOnePerf} {powerUnit}
                </span>
              </p>
            )}
            <p className="mb-1">
              Stage two:
              <span className="float-right font-weight-bold">
                {power.stageTwo} {powerUnit}
              </span>
            </p>
          </div>
          <div className="col-6">
            <p className="font-weight-bold mb-2">Torque</p>
            <p className="mb-1">
              Stock:
              <span className="float-right font-weight-bold">
                {torque.stock} {torqueUnit}
              </span>
            </p>
            <p className="mb-1">
              Stage one {isDiesel ? '(basic)' : ''}:
              <span className="float-right font-weight-bold">
                {torque.stageOneBasic} {torqueUnit}
              </span>
            </p>
            {isDiesel && (
              <p className="mb-1">
                Stage one (performance):
                <span className="float-right font-weight-bold">
                  {torque.stageOnePerf} {torqueUnit}
                </span>
              </p>
            )}
            <p className="mb-1">
              Stage two:
              <span className="float-right font-weight-bold">
                {torque.stageTwo} {torqueUnit}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ vehicle, auth }) => ({ vehicle, auth })

export default connect(mapStateToProps)(ManualResults)
