import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import isEmail from 'validator/lib/isEmail'

import { loginUser, updateAuth } from '../actions/auth'
import AuthTemplate from '../templates/Auth'
import Alert from '../components/common/Alert'

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      emailValid: '',
      password: '',
      passwordValid: ''
    }

    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillUnmount() {
    this.props.updateAuth({ error: null })
  }

  handleEmailChange(e) {
    const email = e.target.value
    const emailValid = isEmail(email)
    this.setState({ email, emailValid })
  }

  handlePasswordChange(e) {
    const password = e.target.value
    const passwordValid = password.length >= 8
    this.setState({ password, passwordValid })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { email, emailValid, password, passwordValid } = this.state
    if (!emailValid || !passwordValid) {
      this.props.updateAuth({ error: 'Invalid email or password' })
    } else {
      this.props.loginUser({ email, password })
    }
  }

  render() {
    const { auth } = this.props

    return (
      <AuthTemplate>
        <h1 className="text-center font-weight-light h3 pb-3">
          Sign in to DynoGen
        </h1>
        <Alert
          error={auth.error}
          success={auth.success}
          onDismissError={() => this.props.updateAuth({ error: null })}
          onDismissSuccess={() => this.props.updateAuth({ success: null })}
        />
        <form className="card mb-4" onSubmit={this.handleSubmit}>
          <div className="card-body p-5">
            <div className="form-group">
              <label className="form-label">Email address</label>
              <input
                autoComplete="username"
                autoCapitalize="none"
                autoCorrect="none"
                autoFocus
                className="form-control"
                onChange={this.handleEmailChange}
                type="text"
                value={this.state.email}
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Password
                <Link
                  className="float-right small font-weight-bold"
                  to="/forgot-password"
                  tabIndex="-1"
                >
                  Forgot password?
                </Link>
              </label>
              <input
                type="password"
                autoComplete="current-password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
                className="form-control"
              />
            </div>
            <div className="form-footer">
              <button
                type="submit"
                className={cn('btn', 'btn-block', 'btn-primary', {
                  'btn-loading': auth.loading
                })}
              >
                Sign in
              </button>
            </div>
          </div>
        </form>
        <div className="text-center border rounded p-3">
          {'New to DynoGen? '}
          <Link to="/setup">Create an account</Link>.
        </div>
      </AuthTemplate>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth }
}

export default connect(
  mapStateToProps,
  { loginUser, updateAuth }
)(LoginForm)
