import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import MainTemplate from '../templates/Main'
import FullScreenLoading from '../components/common/FullScreenLoading'
import Alert from '../components/common/Alert'
import StripeProvider from '../components/stripe/StripeProvider'

class SubscribeScreen extends Component {
  constructor(props) {
    super(props)
    this.state = { plan: null }
    this.renderPlans = this.renderPlans.bind(this)
  }

  renderPlans() {
    return (
      <Fragment>
        <h1>Plans</h1>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <div className="card-body text-center">
                <div className="card-category">Monthly</div>
                <div className="display-4 my-4">£14.99</div>
                <ul className="list-unstyled leading-loose">
                  <li>
                    <b>Unlimited</b> report generation
                  </li>
                  <li>
                    <b>Unlimited</b> report storage
                  </li>
                  <li>Your company branding</li>
                  <li>Vehicle registration lookup</li>
                  <li>Export to PDF and JPEG</li>
                  <li>Instant turnaround</li>
                  <li>No saving</li>
                </ul>
                <div className="text-center mt-6">
                  <button
                    onClick={() => this.setState({ plan: 'monthly' })}
                    className="btn btn-secondary btn-block"
                  >
                    Choose plan
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <div className="card-status bg-primary" />
              <div className="card-body text-center">
                <div className="card-category">Annual</div>
                <div className="display-4 my-4">£129.99</div>
                <ul className="list-unstyled leading-loose">
                  <li>
                    <b>Unlimited</b> report generation
                  </li>
                  <li>
                    <b>Unlimited</b> report storage
                  </li>
                  <li>Your company branding</li>
                  <li>Vehicle registration lookup</li>
                  <li>Export to PDF and JPEG</li>
                  <li>Instant turnaround</li>
                  <li className="text-primary">
                    <b>Save over 27%!</b>
                  </li>
                </ul>
                <div className="text-center mt-6">
                  <button
                    onClick={() => this.setState({ plan: 'annual' })}
                    className="btn btn-primary btn-block"
                  >
                    Choose plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  render() {
    if (this.props.auth.status === 'active') {
      return <Redirect to="/dynos" />
    } else if (this.state.plan === null) {
      return <MainTemplate>{this.renderPlans()}</MainTemplate>
    } else {
      return (
        <MainTemplate>
          <Alert error={this.props.auth.error} />
          <StripeProvider
            plan={this.state.plan}
            onCancel={() => this.setState({ plan: null })}
          />
        </MainTemplate>
      )
    }
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth }
}

export default connect(mapStateToProps)(SubscribeScreen)
