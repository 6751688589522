/* global window */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { updateAuth, refreshAuth } from './actions/auth'
import PrivateRoute from './hoc/PrivateRoute'
import LandingScreen from './screens/LandingScreen'
import FullScreenLoading from './components/common/FullScreenLoading'
import LoginScreen from './screens/LoginScreen'
import SetupScreen from './screens/SetupScreen'
import AwaitingApproval from './screens/AwaitingApproval'
import ForgotPasswordScreen from './screens/ForgotPasswordScreen'
import ResetPasswordEmailSentScreen from './screens/ResetPasswordEmailSentScreen'
import NewPasswordScreen from './screens/NewPasswordScreen'
import DynoListScreen from './screens/DynoListScreen'
import NewDynoScreen from './screens/NewDynoScreen'
import SubscribeScreen from './screens/SubscribeScreen'
import SettingsScreen from './screens/SettingsScreen'
import Error404 from './screens/404'

class Router extends Component {
  componentDidMount() {
    if (window.localStorage.getItem('auth')) {
      this.props.refreshAuth()
    } else {
      this.props.updateAuth({ auth: false })
    }
  }

  render() {
    if (this.props.auth.auth === null) {
      return <FullScreenLoading />
    }

    // prettier-ignore
    return (
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact path="/dynos" component={DynoListScreen} />
          <PrivateRoute exact path="/new-dyno" component={NewDynoScreen} />
          <PrivateRoute exact path="/subscribe" component={SubscribeScreen} />
          <PrivateRoute exact path="/settings" component={SettingsScreen} />
          <Route exact path="/awaiting-approval" component={AwaitingApproval} />
          <Route exact path="/" component={LandingScreen} />
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/setup" component={SetupScreen} />
          <Route exact path="/forgot-password" component={ForgotPasswordScreen} />
          <Route exact path="/reset-password-email-sent" component={ResetPasswordEmailSentScreen} />
          <Route exact path="/new-password" component={NewPasswordScreen} />
          <Route component={Error404} />
        </Switch>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = ({ auth, modal }) => {
  return { auth, modal }
}

export default connect(
  mapStateToProps,
  { updateAuth, refreshAuth }
)(Router)
