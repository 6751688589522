import { PrivateAPI } from '../utils/api'
import {
  UPDATE_VEHICLE,
  RESET_VEHICLE_CURRENT,
  RESET_DYNO_CURRENT
} from './types'

const regErr = 'Cannot find vehicle with that registration'
const serverErr = 'Unable to retrieve vehicle details'
const connErr = 'Unable to connect, please check your internet connection'

const privateAPI = PrivateAPI()

export const updateVehicle = payload => {
  return {
    type: UPDATE_VEHICLE,
    payload
  }
}

export const resetVehicle = () => {
  return {
    type: RESET_VEHICLE_CURRENT
  }
}

export const fetchVehicleMakes = () => async dispatch => {
  try {
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        makes: [],
        models: [],
        years: [],
        fuels: [],
        engines: [],
        power: []
      }
    })
    const res = await privateAPI.get('/vehicles/makes')
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        makes: res.data.makes
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_VEHICLE, payload })
  }
}

export const fetchVehicleModels = ({ make }) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        models: [],
        years: [],
        fuels: [],
        engines: [],
        power: []
      }
    })
    const res = await privateAPI.get(`/vehicles/models?make=${make}`)
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        models: res.data.models
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_VEHICLE, payload })
  }
}

export const fetchVehicleYears = ({ make, model }) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        years: [],
        fuels: [],
        engines: [],
        power: []
      }
    })

    const params = `make=${make}&model=${model}`
    const res = await privateAPI.get(`/vehicles/years?${params}`)
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        years: res.data.years
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_VEHICLE, payload })
  }
}

export const fetchVehicleFuels = ({ make, model, year }) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        fuels: [],
        engines: [],
        power: []
      }
    })

    const params = `make=${make}&model=${model}&year=${year}`
    const res = await privateAPI.get(`/vehicles/fuels?${params}`)
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        fuels: res.data.fuels
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_VEHICLE, payload })
  }
}

export const fetchVehicleEngines = ({
  make,
  model,
  year,
  fuel
}) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        engines: [],
        power: []
      }
    })

    const params = `make=${make}&model=${model}&year=${year}&fuel=${fuel}`
    const res = await privateAPI.get(`/vehicles/engines?${params}`)
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        engines: res.data.engines
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_VEHICLE, payload })
  }
}

export const fetchVehiclePower = ({
  make,
  model,
  year,
  fuel,
  engine
}) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        power: []
      }
    })

    const params = `make=${make}&model=${model}&year=${year}&fuel=${fuel}&engine=${engine}`
    const res = await privateAPI.get(`/vehicles/power?${params}`)
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        power: res.data.power
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_VEHICLE, payload })
  }
}

export const lookupVehicleModel = vehicle => async dispatch => {
  try {
    dispatch({ type: RESET_VEHICLE_CURRENT })
    dispatch({ type: RESET_DYNO_CURRENT })
    dispatch({ type: UPDATE_VEHICLE, payload: { loading: true } })

    const { make, model, year, fuel, engine, power } = vehicle
    const params = `make=${make}&model=${model}&year=${year}&fuel=${fuel}&engine=${engine}&power=${power}`

    const res = await privateAPI.get(`/vehicles?${params}`)
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        current: res.data.vehicle
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_VEHICLE, payload })
  }
}

export const lookupVehicleReg = ({ reg }) => async dispatch => {
  try {
    dispatch({ type: RESET_VEHICLE_CURRENT })
    dispatch({ type: RESET_DYNO_CURRENT })
    dispatch({ type: UPDATE_VEHICLE, payload: { loading: true } })
    const res = await privateAPI.get(`/vehicles/lookup/reg/${reg}`)
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        loading: false,
        current: res.data.vehicle
      }
    })
  } catch (err) {
    const payload = { loading: false }
    if (err.response && err.response.status === 404) {
      payload.error = regErr
    } else if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }
    dispatch({ type: UPDATE_VEHICLE, payload })
  }
}
