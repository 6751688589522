import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectStripe, CardElement } from 'react-stripe-elements'
import cn from 'classnames'

import FullScreenLoading from '../common/FullScreenLoading'
import { getUserBillingAuth, subscribeUserToPlan } from '../../actions/auth'

class SubscriptionForm extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, card: null, error: null }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.props.getUserBillingAuth({
      plan: this.props.plan
    })
  }

  async handleSubmit(e) {
    e.preventDefault()
    this.setState({ loading: true })
    const { paymentIntent, error } = await this.props.stripe.handleCardPayment(
      this.props.auth.billingAuthToken,
      this.state.card
    )
    this.setState({ loading: false })

    if (paymentIntent) {
      this.props.subscribeUserToPlan({
        plan: this.props.plan,
        paymentMethod: paymentIntent.payment_method
      })
    } else {
      this.setState({ error })
    }
  }

  render() {
    const btnClasses = cn({
      btn: true,
      'btn-primary': true,
      'mt-6': true,
      disabled: this.props.loading || this.state.loading
    })

    if (this.props.auth.loading) {
      return <FullScreenLoading />
    }

    return (
      <div className="row">
        <div className="col-12 col-sm-8 col-md-6">
          <form
            className="bg-white border rounded p-4"
            onSubmit={this.handleSubmit}
          >
            <label>Card details</label>
            <CardElement onReady={card => this.setState({ card })} />
            <button
              type="submit"
              className={btnClasses}
              disabled={this.props.loading || this.state.loading}
            >
              Subscribe to plan
            </button>
          </form>
        </div>
        <div className="col-12">
          <button
            className="btn btn-link border-0 p-0 mt-4 font-weight-normal"
            onClick={this.props.onCancel}
          >
            Change plan
          </button>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ auth }) => ({ auth }),
  { getUserBillingAuth, subscribeUserToPlan }
)(injectStripe(SubscriptionForm))
