import React from 'react'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

const Alert = props => {
  const { error, success, onDismissError, onDismissSuccess } = props
  if (error) {
    return (
      <div className="alert alert-dismissible alert-danger">
        {error}
        {onDismissError && (
          <span className="close" onClick={onDismissError}>
            <Icon icon={faTimes} />
          </span>
        )}
      </div>
    )
  } else if (success) {
    return (
      <div className="alert alert-dismissible alert-success">
        {success}
        {onDismissSuccess && (
          <span className="close" onClick={onDismissSuccess}>
            <Icon icon={faTimes} />
          </span>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default Alert
