/* global window */
import axios from 'axios'

import { GCS_URL } from '../config'
import { logoutUser } from '../actions/auth'
import Store from '../Store'

export const PrivateAPI = () => {
  const axiosInstance = axios.create({
    baseURL: '/api'
  })

  axiosInstance.interceptors.request.use(config => {
    const auth = window.localStorage.getItem('auth')
    if (!auth) {
      throw new axios.Cancel()
    }
    config.headers.common['Authorization'] = `bearer ${auth}`
    return config
  })

  axiosInstance.interceptors.response.use(
    res => {
      if (res.data.token) {
        window.localStorage.setItem('auth', res.data.token)
      }

      return res
    },
    err => {
      if (err instanceof axios.Cancel) {
        Store.dispatch(logoutUser())
        throw err
      }

      if (err.response) {
        if (err.response.status === 401) {
          Store.dispatch(logoutUser())
        }
        throw err
      }
    }
  )

  return axiosInstance
}

// Keep as function for a consistent API
export const PublicAPI = () => {
  return axios.create({
    baseURL: '/api'
  })
}

export const StorageAPI = () => {
  return axios.create({
    baseURL: GCS_URL
  })
}
