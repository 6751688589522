/* global process */
// https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/template/README.md#adding-custom-environment-variables
import dev from './dev'
import prod from './prod'

const production = process.env.NODE_ENV === 'production'
export const API_URL = production ? prod.API_URL : dev.API_URL
export const GCS_BUCKET_NAME = production ? prod.GCS_BUCKET_NAME : dev.GCS_BUCKET_NAME
export const GCS_CLIENT_EMAIL = production ? prod.GCS_CLIENT_EMAIL : dev.GCS_CLIENT_EMAIL
export const GCS_URL = production ? prod.GCS_URL : dev.GCS_URL
export const STRIPE_KEY = production ? prod.STRIPE_KEY : dev.STRIPE_KEY
