import {
  UPDATE_DYNO,
  UPDATE_DYNO_CURRENT,
  RESET_DYNO_CURRENT
} from '../actions/types'

const INITIAL_STATE = {
  current: {
    _id: null,
    remap: null,
    pdfKey: null,
    trial: null,
    vehicle: {
      type: '',
      make: '',
      range: '',
      fuel: '',
      model: '',
      reg: '',
      cylinder: '',
      engine: '',
      valve: '',
      transmission: '',
      gears: '',
      year: '',
      ecuType: '',
      manual: null,
      bhp: {
        stock: null,
        stageOneBasic: null,
        stageOnePerf: null,
        stageTwo: null
      },
      torque: {
        stock: null,
        stageOneBasic: null,
        stageOnePerf: null,
        stageTwo: null
      }
    }
  },
  list: [],
  loading: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DYNO:
      return {
        ...state,
        ...action.payload
      }

    case UPDATE_DYNO_CURRENT:
      return {
        ...state,
        current: {
          ...state.current,
          ...action.payload
        }
      }

    case RESET_DYNO_CURRENT:
      return {
        ...state,
        current: {
          ...INITIAL_STATE.current
        }
      }

    default:
      return state
  }
}
