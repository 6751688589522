import React from 'react'
import { Link } from 'react-router-dom'

import AuthTemplate from '../templates/Auth'

const ResetPasswordEmailSentScreen = () => {
  return (
    <AuthTemplate>
      <h1 className="text-center font-weight-light h3 pb-3">
        Reset your password
      </h1>
      <div className="card mb-4">
        <div className="card-body p-5">
          <p>
            Check your email for a link to reset your password. If it doesn't
            appear within a few minutes, check your spam folder.
          </p>
          <Link to="/login" className="btn btn-block btn-secondary">
            Return to sign in
          </Link>
        </div>
      </div>
    </AuthTemplate>
  )
}

export default ResetPasswordEmailSentScreen
