import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import cn from 'classnames'
import qs from 'qs'

import AuthTemplate from '../templates/Auth'
import FullScreenLoading from '../components/common/FullScreenLoading'
import Alert from '../components/common/Alert'
import {
  updateAuth,
  checkNewPasswordToken,
  saveNewPassword
} from '../actions/auth'

class NewPasswordScreen extends Component {
  constructor(props) {
    super(props)
    this.state = { password: '', passwordValid: false }
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search)
    const token = params['?t']
    if (!token) {
      this.props.updateAuth({ error: 'Invalid reset password link' })
    } else {
      this.props.checkNewPasswordToken({ token })
    }
  }

  componentWillUnmount() {
    this.props.updateAuth({ error: null })
  }

  handlePasswordChange(e) {
    const password = e.target.value
    const passwordValid = password.length >= 8
    this.setState({ password, passwordValid })
  }

  handleSubmit(e) {
    e.preventDefault()
    if (!this.state.passwordValid) {
      this.props.updateAuth({ error: 'Must be at least 8 characters' })
    } else {
      this.props.saveNewPassword({
        password: this.state.password,
        token: this.props.auth.resetPasswordToken
      })
    }
  }

  render() {
    const { auth } = this.props
    if (!auth.resetPasswordToken && !auth.error) {
      return <FullScreenLoading />
    } else if (!auth.resetPasswordToken && auth.error) {
      return <Redirect to="/login" />
    } else if (auth.success) {
      return <Redirect to="/login" />
    } else {
      return (
        <AuthTemplate>
          <h1 className="text-center font-weight-light h3 pb-3">
            Choose new password
          </h1>
          <Alert
            error={auth.error}
            onDismissError={() => this.props.updateAuth({ error: null })}
          />
          <form className="card mb-4" onSubmit={this.handleSubmit}>
            <div className="card-body p-5">
              <p className="text-muted">
                Enter a new password for your DynoGen account below.
              </p>
              <div className="form-group">
                <label className="form-label">New password</label>
                <div className="input-icon">
                  <input
                    type="password"
                    placeholder="Enter new password"
                    value={auth.password}
                    autoComplete="new-password"
                    autoFocus
                    onChange={this.handlePasswordChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-footer">
                <button
                  type="submit"
                  className={cn('btn', 'btn-block', 'btn-primary', {
                    'btn-loading': auth.loading
                  })}
                >
                  Create new password
                </button>
              </div>
            </div>
          </form>
        </AuthTemplate>
      )
    }
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth }
}

export default connect(
  mapStateToProps,
  { updateAuth, checkNewPasswordToken, saveNewPassword }
)(NewPasswordScreen)
