import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import SiteTemplate from '../templates/Site'
import CreateAccountForm from '../forms/CreateAccountForm'

class SetupScreen extends Component {
  render() {
    if (this.props.auth.id) {
      return <Redirect to="/dynos" />
    }

    return (
      <SiteTemplate>
        <div className="container py-6">
          <h1>Join DynoGen</h1>
          <p className="h4 text-muted font-weight-normal">
            Easier dyno reports for vehicle tuning businesses.
          </p>
          <CreateAccountForm />
        </div>
      </SiteTemplate>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth }
}

export default connect(
  mapStateToProps,
  null
)(SetupScreen)
