/* global document */
import PDFJS from 'pdfjs-dist'

export default async (url, filename) => {
  PDFJS.disableWorker = true
  PDFJS.GlobalWorkerOptions.workerSrc =
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.489/pdf.worker.js'
  const pdf = await PDFJS.getDocument(url)
  const page = await pdf.getPage(1)
  const scale = 3
  const viewport = page.getViewport(scale)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  canvas.height = viewport.height
  canvas.width = viewport.width
  const renderContext = {
    canvasContext: ctx,
    viewport: viewport
  }
  await page.render(renderContext)
  const image = canvas.toDataURL('image/jpeg')
  const link = document.createElement('a')
  link.href = image
  link.download = `${filename}.jpeg`
  link.click()
}
