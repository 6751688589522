/* global FileReader, Image, document */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'
import isEmail from 'validator/lib/isEmail'
import Pica from 'pica/dist/pica.min'

import Alert from '../components/common/Alert'
import { updateAuth, signupUser } from '../actions/auth'

class CreateAccountForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      businessName: '',
      businessNameValid: false,
      businessLogo: null,
      businessLogoUrl: null,
      businessLogoValid: false,
      email: '',
      emailValid: false,
      password: '',
      passwordValid: false,
      preferredPower: 'bhp',
      preferredTorque: 'nm'
    }

    this.handleBusinessNameChange = this.handleBusinessNameChange.bind(this)
    this.handleBusinessLogoChange = this.handleBusinessLogoChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.clearBusinessLogo = this.clearBusinessLogo.bind(this)
  }

  componentWillUnmount() {
    this.props.updateAuth({ error: null })
  }

  handleBusinessNameChange(e) {
    const businessName = e.target.value
    const businessNameValid = businessName.length > 0
    this.setState({ businessName, businessNameValid })
  }

  handleBusinessLogoChange(e) {
    const file = e.target.files[0]
    if (file) {
      const isJpeg = file.type.indexOf('image/jpeg') > -1
      const isPng = file.type.indexOf('image/png') > -1
      const correctType = isJpeg || isPng
      this.setState({ businessLogoValid: !correctType })

      if (correctType) {
        const reader = new FileReader()

        reader.onload = e => {
          this.setState({ businessLogoUrl: e.target.result })

          const img = new Image()
          img.onload = async () => {
            const MAX_WIDTH = 300
            const MAX_HEIGHT = 300
            const MIME_TYPE = 'image/png'
            let width = img.width
            let height = img.height

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width
                width = MAX_WIDTH
              }
            } else if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height
              height = MAX_HEIGHT
            }

            const canvas = document.createElement('canvas')
            canvas.width = width
            canvas.height = height

            this.pica = Pica()
            const result = await this.pica.resize(img, canvas, {
              unsharpAmount: 80,
              unsharpRadius: 0.6,
              unsharpThreshold: 2,
              alpha: true
            })

            const blob = await this.pica.toBlob(result, MIME_TYPE)
            this.setState({ businessLogo: blob })
          }
          img.src = e.target.result
        }
        reader.readAsDataURL(file)
      } else {
        this.props.updateAuth({ error: 'Invalid image type' })
      }
    }
  }

  handleEmailChange(e) {
    const email = e.target.value
    const emailValid = isEmail(email)
    this.setState({ email, emailValid })
  }

  handlePasswordChange(e) {
    const password = e.target.value
    const passwordValid = password.length >= 8
    this.setState({ password, passwordValid })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { updateAuth, signupUser } = this.props
    const {
      businessName,
      businessNameValid,
      businessLogo,
      email,
      emailValid,
      password,
      passwordValid,
      preferredPower,
      preferredTorque
    } = this.state

    if (!businessNameValid) {
      updateAuth({ error: 'Please enter a business name' })
    } else if (!emailValid) {
      updateAuth({ error: 'Please enter a valid email address' })
    } else if (!passwordValid) {
      updateAuth({ error: 'Passwords must be at least 8 characters' })
    } else {
      signupUser({
        businessName,
        businessLogo,
        email,
        password,
        preferredPower,
        preferredTorque
      })
    }
  }

  clearBusinessLogo() {
    this.setState({
      businessLogo: null,
      businessLogoUrl: null,
      businessLogoValid: false
    })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row my-4">
          <div className="col-12 col-md-6">
            <Alert
              error={this.props.auth.error}
              onDismissError={() => this.props.updateAuth({ error: null })}
            />
            <div className="form-group">
              <label className="form-label">Business name</label>
              <input
                autoComplete="username"
                autoCapitalize="none"
                autoCorrect="none"
                autoFocus
                className="form-control"
                onChange={this.handleBusinessNameChange}
                type="text"
                value={this.state.businessName}
              />
              <small className="form-text text-muted">
                This will appear on your DynoGen reports.
              </small>
            </div>
            <div className="form-group">
              <div className="form-label">Business logo</div>
              {this.state.businessLogoUrl ? (
                <Fragment>
                  <img
                    className="logo-upload-img"
                    src={this.state.businessLogoUrl}
                    alt="business logo"
                  />
                  <button
                    type="button"
                    className="btn btn-link border-0 d-block p-0"
                    onClick={this.clearBusinessLogo}
                  >
                    Remove logo
                  </button>
                </Fragment>
              ) : (
                <div className="custom-file logo-upload">
                  <input
                    type="file"
                    className="custom-file-input"
                    accept="image/png, image/jpeg"
                    onChange={this.handleBusinessLogoChange}
                  />
                  <label className="logo-upload-label">
                    Click here to upload
                  </label>
                  <small className="form-text text-muted">
                    PNG and JPEG files only.
                  </small>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="powerUnit">Preferred power unit</label>
                  <select
                    className="custom-select"
                    id="powerUnit"
                    onChange={e =>
                      this.setState({ preferredPower: e.target.value })
                    }
                    value={this.state.preferredPower}
                  >
                    <option key="bhp" value="bhp">
                      BHP
                    </option>
                    <option key="ps" value="ps">
                      PS
                    </option>
                    <option key="kw" value="kw">
                      Kw
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="torqueUnit">Preferred torque unit</label>
                  <select
                    className="custom-select"
                    id="torqueUnit"
                    onChange={e =>
                      this.setState({ preferredTorque: e.target.value })
                    }
                    value={this.state.preferredTorque}
                  >
                    <option key="nm" value="nm">
                      Nm
                    </option>
                    <option key="ibft" value="ibft">
                      Ib/ft
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Email address</label>
              <input
                autoComplete="email"
                autoCapitalize="none"
                autoCorrect="none"
                className="form-control"
                onChange={this.handleEmailChange}
                type="text"
                value={this.state.email}
              />
              <small className="form-text text-muted">
                You will use this to login to your account.
              </small>
            </div>
            <div className="form-group">
              <label className="form-label">Password</label>
              <input
                autoComplete="new-password"
                autoCapitalize="none"
                autoCorrect="none"
                className="form-control"
                onChange={this.handlePasswordChange}
                type="password"
                value={this.state.password}
              />
              <small className="form-text text-muted">
                Must be at least 8 characters.
              </small>
            </div>
            <button
              type="submit"
              className={cn('btn', 'btn-primary', {
                'btn-loading': this.props.auth.loading
              })}
            >
              Start 14 day free trial
            </button>
          </div>
          <div className="col-12 col-md">
            <div className="card mx-auto mt-6 mt-md-4 card-signup-features">
              <div className="card-header p-4">
                <h5 className="m-0">You'll love DynoGen</h5>
              </div>
              <div className="card-body p-4">
                <p>
                  <span className="font-weight-bold">Unlimited </span>
                  report generation
                </p>
                <p>
                  <span className="font-weight-bold">Unlimited </span>
                  report storage
                </p>
              </div>
              <div className="card-body p-4">
                <p className="mb-2">Vehicle registration lookup</p>
                <p className="mb-2">Export to PDF and JPEG</p>
                <p>No long wait times</p>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth }
}

export default connect(
  mapStateToProps,
  { updateAuth, signupUser }
)(CreateAccountForm)
