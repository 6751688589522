import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'

const Error404 = () => {
  return (
    <div className="page">
      <div className="page-content">
        <div className="container text-center">
          <h1 className="display-1 text-muted mb-5">
            404
          </h1>
          <h2 className="mb-3">
            Page not found
          </h2>
          <p className="h4 text-muted font-weight-normal mb-7">
            We're sorry, but the page you're looking for can't be found.
          </p>
          <Link className="btn btn-primary" to="/">
            <Icon icon={faArrowLeft} className="mr-2" />
            Go back
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Error404
