import { combineReducers } from 'redux'

import authReducer from './authReducer'
import dynoReducer from './dynoReducer'
import vehicleReducer from './vehicleReducer'
import { LOGOUT_AUTH } from '../actions/types'

const appReducer = combineReducers({
  auth: authReducer,
  dyno: dynoReducer,
  vehicle: vehicleReducer
})

export default (state, action) => {
  if (action.type === LOGOUT_AUTH) {
    state = {
      auth: { auth: false }
    }
  }

  return appReducer(state, action)
}
