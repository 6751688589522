import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import logo from '../../assets/logos/logo.png'
import logo2x from '../../assets/logos/logo@2x.png'
import logo3x from '../../assets/logos/logo@3x.png'
import SiteNav from './SiteNav'
import AppNav from './AppNav'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { navOpen: false }
    this.toggleNav = this.toggleNav.bind(this)
    this.getDaysRemaining = this.getDaysRemaining.bind(this)
    this.renderTrialBanner = this.renderTrialBanner.bind(this)
  }

  toggleNav() {
    this.setState({ navOpen: !this.state.navOpen })
  }

  getDaysRemaining() {
    const trialExpiry = moment.unix(this.props.auth.trialEnd)
    const now = moment()
    const timeLeft = moment.duration(trialExpiry.diff(now))
    const asDays = Math.round(timeLeft.asDays())
    return asDays
  }

  renderTrialBanner() {
    const daysRemaining = this.getDaysRemaining()
    if (daysRemaining < 1) {
      return (
        <div className="bg-danger text-white py-2 text-center">
          <div className="container">
            Your trial has expired
            <Link className="btn btn-sm ml-2 btn-secondary" to="/subscribe">
              Choose a plan
            </Link>
          </div>
        </div>
      )
    } else {
      return (
        <div className="bg-primary text-white py-2 text-center">
          <div className="container">
            Your trial expires in {this.getDaysRemaining()} days.
            <Link className="btn btn-sm ml-2 btn-secondary" to="/subscribe">
              Choose a plan
            </Link>
          </div>
        </div>
      )
    }
  }

  render() {
    const { status, trial } = this.props.auth
    const trialExpired = trial ? this.getDaysRemaining() < 1 : false
    const pastDue = status === 'past_due'
    const canceled = status === 'canceled'
    const unpaid = status === 'unpaid'
    const active = !trialExpired && !pastDue && !canceled && !unpaid

    return (
      <Fragment>
        <header className="header">
          <div className="container">
            <div className="d-flex">
              <Link to="/" className="header-brand">
                <img
                  className="header-brand-img"
                  src={logo}
                  srcSet={`${logo2x} 2x, ${logo3x} 3x`}
                  alt="DynoGen logo"
                />
              </Link>
              {this.props.type === 'site' ? (
                <SiteNav />
              ) : (
                <AppNav active={active} />
              )}
            </div>
          </div>
        </header>
        {this.props.auth.trial && this.renderTrialBanner()}
      </Fragment>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth }
}

export default connect(
  mapStateToProps,
  null
)(Header)
