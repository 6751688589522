import { UPDATE_AUTH, LOGOUT_AUTH } from '../actions/types'

const INITIAL_STATE = {
  _id: null,
  businessName: '',
  email: '',
  logoKey: '',
  approved: null,
  settings: {
    preferredPower: '',
    preferredTorque: ''
  },
  auth: null,
  billingAuthToken: null,
  resetPasswordEmailSent: null,
  resetPasswordToken: null,
  loading: false,
  error: null,
  success: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH:
      return { ...state, ...action.payload }

    case LOGOUT_AUTH:
      return { ...state, auth: false }

    default:
      return state
  }
}
