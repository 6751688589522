import React, { Component } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import { logoutUser } from '../../actions/auth'

class AppNav extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const navStyles = cn({
      'd-sm-block': true,
      collapse: true,
      show: this.state.isOpen
    })

    const newDynoStyles = cn({
      btn: true,
      'btn-primary': true,
      'mx-1': true,
      disabled: !this.props.active
    })

    return (
      <div className="d-flex ml-auto">
        <button
          className="btn btn-link border-0 header-toggler d-flex d-sm-none clickable order-2"
          onClick={this.toggle}
        >
          <span className="header-toggler-icon" />
        </button>
        <div className={navStyles}>
          <Link className="align-self-center pr-sm-4 my-4 my-sm-0" to="/dynos">
            View dynos
          </Link>
          {this.props.auth.status !== 'active' && (
            <Link
              className="align-self-center pr-sm-4 my-4 my-sm-0"
              to="/subscribe"
            >
              Plans
            </Link>
          )}
          <Link
            className="align-self-center pr-sm-4 my-4 my-sm-0"
            to="/settings"
          >
            Settings
          </Link>
          <button
            type="button"
            onClick={this.props.logoutUser}
            className="
            align-self-center
            pr-sm-4
            btn-link
            border-0
            clickable
            my-4
            my-sm-0
            "
          >
            Logout
          </button>
          <Link
            to={this.props.active ? '/new-dyno' : '/dynos'}
            className={newDynoStyles}
          >
            New dyno
          </Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth }
}

export default connect(
  mapStateToProps,
  { logoutUser }
)(AppNav)
