import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import logo from '../assets/logos/logo.png'
import logo2x from '../assets/logos/logo@2x.png'
import logo3x from '../assets/logos/logo@3x.png'

class AwaitingApproval extends Component {
  render() {
    if (this.props.auth.approved) {
      return <Redirect to="/dynos" />
    }

    if (!this.props.auth._id) {
      return <Redirect to="/" />
    }

    return (
      <div className="page">
        <div className="page-single align-items-start">
          <div className="container">
            <div className="row">
              <div className="col col-login mx-auto">
                <div className="text-center mb-6 mx-8 mt-6">
                  <img
                    src={logo}
                    srcSet={`${logo2x} 2x, ${logo3x} 3x`}
                    alt="DynoGen logo"
                  />
                </div>
                <h1 className="text-center font-weight-light h3 pb-3">
                  Thank you!
                </h1>
                <div className="card mb-4">
                  <div className="card-body p-5 text-center">
                    <p>We have received your request to create an account.</p>
                    <p>
                      Your account is currently being reviewed by a member of
                      our team. We will notify you once your account has been
                      approved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth }
}

export default connect(
  mapStateToProps,
  null
)(AwaitingApproval)
