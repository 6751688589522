import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import logo from '../assets/logos/logo.png'
import logo2x from '../assets/logos/logo@2x.png'
import logo3x from '../assets/logos/logo@3x.png'

const AuthTemplate = props => {
  if (props.auth.approved === false) {
    return <Redirect to="/awaiting-approval" />
  }

  if (props.auth._id) {
    return <Redirect to="/dynos" />
  }

  return (
    <div className="page">
      <div className="page-single align-items-start">
        <div className="container">
          <div className="row">
            <div className="col col-login mx-auto">
              <div className="text-center mb-6 mx-8 mt-6">
                <img
                  src={logo}
                  srcSet={`${logo2x} 2x, ${logo3x} 3x`}
                  alt="DynoGen logo"
                />
              </div>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  return { auth }
}

export default connect(
  mapStateToProps,
  null
)(AuthTemplate)
