import { PrivateAPI } from '../utils/api'
import { UPDATE_DYNO, UPDATE_DYNO_CURRENT, RESET_DYNO_CURRENT } from './types'

const serverErr = 'Unable to retrieve vehicle details'
const connErr = 'Unable to connect, please check your internet connection'

const privateAPI = PrivateAPI()

export const updateDyno = values => {
  return {
    type: UPDATE_DYNO_CURRENT,
    payload: values
  }
}

export const resetDyno = () => {
  return {
    type: RESET_DYNO_CURRENT
  }
}

export const fetchDynoList = () => async dispatch => {
  try {
    dispatch({ type: UPDATE_DYNO, payload: { loading: true } })
    const res = await privateAPI.get('/dynos')
    dispatch({
      type: UPDATE_DYNO,
      payload: {
        loading: false,
        list: res.data.dynos
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_DYNO, payload })
  }
}

export const createDyno = ({ vehicle, remap }) => async dispatch => {
  try {
    dispatch({ type: UPDATE_DYNO, payload: { loading: true } })
    const res = await privateAPI.get(`/dynos/${vehicle}/${remap}`)
    dispatch({
      type: UPDATE_DYNO,
      payload: {
        loading: false,
        current: res.data.dyno
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_DYNO, payload })
  }
}
