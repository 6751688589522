import React, { Component } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'

import { lookupVehicleReg, updateVehicle } from '../actions/vehicle'
import { resetDyno } from '../actions/dyno'
import reg from '../assets/png/reg.png'
import reg2x from '../assets/png/reg@2x.png'
import reg3x from '../assets/png/reg@3x.png'

class RegLookup extends Component {
  constructor(props) {
    super(props)
    this.state = { reg: '', regValid: false, lookupLoading: false }

    this.handleRegChange = this.handleRegChange.bind(this)
    this.handleRegSubmit = this.handleRegSubmit.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehicle.current._id !== this.props.vehicle.current._id) {
      if (this.state.lookupLoading) {
        this.setState({ lookupLoading: false })
      }
    }

    if (this.props.vehicle.error && this.state.lookupLoading) {
      this.setState({ lookupLoading: false })
    }
  }

  handleRegChange(e) {
    // Remove any errors shown
    this.props.updateVehicle({ error: null })

    const str = e.target.value

    // Allow uppercase, lowercase, numbers and spaces
    if (/^[a-zA-Z0-9\s]*$/.test(str)) {
      const reg = str.toUpperCase()

      // Make sure we don't just have spaces
      // Reg must be between 1 to 7 characters long
      const spaceCount = reg.split(' ').length - 1
      const charCount = reg.length - spaceCount
      const regValid = charCount >= 1 && charCount <= 7

      // Don't allow any more than 7 characters to be entered
      if (charCount <= 7) {
        this.setState({ reg, regValid, regChanged: true })
      }
    }
  }

  handleRegSubmit(e) {
    e.preventDefault()

    if (this.state.regValid) {
      this.props.updateVehicle({ error: null })
      this.setState({ lookupLoading: true })
      this.props.lookupVehicleReg({ reg: this.state.reg })
    } else {
      this.props.updateVehicle({ error: 'Reg number is required' })
    }
  }

  render() {
    const btnCn = cn({
      btn: true,
      'btn-primary': true,
      'btn-loading': this.state.lookupLoading
    })

    return (
      <form onSubmit={this.handleRegSubmit}>
        <div className="form-group">
          <div className="reg-input">
            <img
              src={reg}
              srcSet={`${reg2x} 2x, ${reg3x} 3x`}
              alt="Registration lookup"
            />
            <input
              onChange={this.handleRegChange}
              type="text"
              value={this.state.reg}
            />
          </div>
          <small className="text-muted">Enter vehicle registration.</small>
        </div>
        <button
          type="submit"
          className={btnCn}
          disabled={this.state.lookupLoading || !this.state.regValid}
        >
          Search for vehicle
        </button>
      </form>
    )
  }
}

const mapStateToProps = ({ vehicle }) => ({ vehicle })

export default connect(
  mapStateToProps,
  { lookupVehicleReg, updateVehicle, resetDyno }
)(RegLookup)
