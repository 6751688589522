import React, { Component } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'

import { createDyno, resetDyno } from '../actions/dyno'

class GenerateDynoForm extends Component {
  constructor(props) {
    super(props)
    this.state = { remap: 'stageOneBasic', loading: false }

    this.handleRemapChange = this.handleRemapChange.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  handleRemapChange(e) {
    this.setState({ remap: e.target.value })
    this.props.resetDyno()
  }

  handleOnClick() {
    const { current } = this.props.vehicle
    const { remap } = this.state
    this.setState({ loading: true })
    this.props.createDyno({ vehicle: current._id, remap })
  }

  render() {
    const { vehicle, dyno } = this.props
    const isDiesel = vehicle.current.fuel.toLowerCase().includes('diesel')

    const btnCn = cn({
      btn: true,
      'btn-primary': true,
      'btn-loading': dyno.loading && this.state.loading
    })

    return (
      <div className="my-6 my-md-4">
        <label htmlFor="remap">Remap type</label>
        <div className="row">
          <div className="col-12 col-sm mb-3">
            <select
              className="custom-select"
              id="remap"
              onChange={this.handleRemapChange}
              value={this.state.remap}
            >
              <option value="stageOneBasic">
                Stage one {isDiesel && '(basic)'}
              </option>
              {isDiesel && (
                <option value="stageOnePerf">Stage one (performance)</option>
              )}
              <option value="stageTwo">Stage two</option>
            </select>
          </div>
          <div className="pl-3 pl-sm-0 pr-sm-3">
            <button
              type="button"
              className={btnCn}
              onClick={this.handleOnClick}
              disabled={vehicle.loading || dyno.current.pdfKey}
            >
              Generate dyno
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ vehicle, dyno }) => ({ vehicle, dyno })

export default connect(
  mapStateToProps,
  { createDyno, resetDyno }
)(GenerateDynoForm)
