import React, { Component } from 'react'
import { Elements } from 'react-stripe-elements'

import SubscriptionForm from './SubscriptionForm'

class StripeElements extends Component {
  render() {
    return (
      <Elements>
        <SubscriptionForm {...this.props} />
      </Elements>
    )
  }
}

export default StripeElements
