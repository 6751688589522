import React, { Component } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'

import { resetDyno } from '../actions/dyno'
import {
  fetchVehicleMakes,
  fetchVehicleModels,
  fetchVehicleYears,
  fetchVehicleFuels,
  fetchVehicleEngines,
  fetchVehiclePower,
  lookupVehicleModel,
  updateVehicle
} from '../actions/vehicle'

class ManualLookup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      make: null,
      model: null,
      year: null,
      fuel: null,
      power: null,
      lookupLoading: false
    }

    this.handleMakeChange = this.handleMakeChange.bind(this)
    this.handleModelChange = this.handleModelChange.bind(this)
    this.handleYearChange = this.handleYearChange.bind(this)
    this.handleFuelChange = this.handleFuelChange.bind(this)
    this.handleEngineChange = this.handleEngineChange.bind(this)
    this.handlePowerChange = this.handlePowerChange.bind(this)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
  }

  componentDidMount() {
    this.props.fetchVehicleMakes()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehicle.loading && !this.props.vehicle.loading) {
      if (this.state.lookupLoading) {
        this.setState({ lookupLoading: false })
      }
    }

    if (prevProps.vehicle.error !== this.props.vehicle.error) {
      if (this.state.lookupLoading) {
        this.setState({ lookupLoading: false })
      }
    }
  }

  handleMakeChange(e) {
    const make = e.target.value || null
    this.setState({
      make,
      model: null,
      year: null,
      fuel: null,
      engine: null,
      power: null
    })

    if (make) {
      this.props.fetchVehicleModels({ make })
    } else {
      this.props.updateVehicle({
        makes: [],
        models: [],
        years: [],
        fuels: [],
        engines: [],
        power: []
      })
    }
  }

  handleModelChange(e) {
    const model = e.target.value || null
    this.setState({
      model,
      year: null,
      fuel: null,
      engine: null,
      power: null
    })

    if (model) {
      this.props.fetchVehicleYears({
        make: this.state.make,
        model
      })
    } else {
      this.props.updateVehicle({
        models: [],
        years: [],
        fuels: [],
        engines: [],
        power: []
      })
    }
  }

  handleYearChange(e) {
    const year = e.target.value || null
    this.setState({
      year,
      fuel: null,
      engine: null,
      power: null
    })

    if (year) {
      this.props.fetchVehicleFuels({
        make: this.state.make,
        model: this.state.model,
        year
      })
    } else {
      this.props.updateVehicle({
        years: [],
        fuels: [],
        engines: [],
        power: []
      })
    }
  }

  handleFuelChange(e) {
    const fuel = e.target.value || null
    this.setState({
      fuel,
      engine: null,
      power: null
    })

    if (fuel) {
      this.props.fetchVehicleEngines({
        make: this.state.make,
        model: this.state.model,
        year: this.state.year,
        fuel
      })
    } else {
      this.props.updateVehicle({
        fuels: [],
        engines: [],
        power: []
      })
    }
  }

  handleEngineChange(e) {
    const engine = e.target.value || null
    this.setState({
      engine,
      power: null
    })

    if (engine) {
      this.props.fetchVehiclePower({
        make: this.state.make,
        model: this.state.model,
        year: this.state.year,
        fuel: this.state.fuel,
        engine
      })
    } else {
      this.props.updateVehicle({
        engines: [],
        power: []
      })
    }
  }

  handlePowerChange(e) {
    const power = e.target.value || null
    this.setState({ power })
  }

  handleOnSubmit(e) {
    e.preventDefault()

    if (this.state.power) {
      this.setState({ lookupLoading: true })
      this.props.resetDyno()
      this.props.lookupVehicleModel(this.state)
    } else {
      this.props.updateVehicle({ error: 'Please select a power' })
    }
  }

  render() {
    const { vehicle } = this.props

    const btnCn = cn({
      btn: true,
      'btn-primary': true,
      'btn-loading': this.state.lookupLoading
    })

    return (
      <form onSubmit={this.handleOnSubmit}>
        <div className="form-group">
          <label htmlFor="make">Vehicle make</label>
          <select
            className="custom-select"
            id="make"
            disabled={!vehicle.makes.length}
            onChange={this.handleMakeChange}
            value={this.state.make || ''}
          >
            <option value="">Select vehicle make</option>
            {vehicle.makes.map(make => {
              return (
                <option key={make} value={make}>
                  {make}
                </option>
              )
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="model">Vehicle model</label>
          <select
            className="custom-select"
            id="model"
            disabled={!vehicle.models.length}
            onChange={this.handleModelChange}
            value={this.state.model || ''}
          >
            <option value="">Select vehicle model</option>
            {vehicle.models.map(model => {
              return (
                <option key={model} value={model}>
                  {model}
                </option>
              )
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="year">Vehicle year</label>
          <select
            className="custom-select"
            id="year"
            disabled={!vehicle.years.length}
            onChange={this.handleYearChange}
            value={this.state.year || ''}
          >
            <option value="">Select vehicle year</option>
            {vehicle.years.map(year => {
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              )
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="fuel">Vehicle fuel</label>
          <select
            className="custom-select"
            id="fuel"
            disabled={!vehicle.fuels.length}
            onChange={this.handleFuelChange}
            value={this.state.fuel || ''}
          >
            <option value="">Select vehicle fuel</option>
            {vehicle.fuels.map(fuel => {
              return (
                <option key={fuel} value={fuel}>
                  {fuel}
                </option>
              )
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="engine">Vehicle engine</label>
          <select
            className="custom-select"
            id="engine"
            disabled={!vehicle.engines.length}
            onChange={this.handleEngineChange}
            value={this.state.engine || ''}
          >
            <option value="">Select vehicle engine</option>
            {vehicle.engines.map(engine => {
              return (
                <option key={engine} value={engine}>
                  {engine}
                </option>
              )
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="power">Vehicle power</label>
          <select
            className="custom-select"
            id="power"
            disabled={!vehicle.power.length}
            onChange={this.handlePowerChange}
            value={this.state.power || ''}
          >
            <option value="">Select vehicle power</option>
            {vehicle.power.map(power => {
              return (
                <option key={power} value={power}>
                  {power}
                </option>
              )
            })}
          </select>
        </div>
        <button
          type="submit"
          className={btnCn}
          disabled={!this.state.power || this.state.lookupLoading}
        >
          Select vehicle
        </button>
      </form>
    )
  }
}

const mapStateToProps = ({ vehicle }) => ({ vehicle })

export default connect(
  mapStateToProps,
  {
    fetchVehicleMakes,
    fetchVehicleModels,
    fetchVehicleYears,
    fetchVehicleFuels,
    fetchVehicleEngines,
    fetchVehiclePower,
    lookupVehicleModel,
    updateVehicle,
    resetDyno
  }
)(ManualLookup)
