import { UPDATE_VEHICLE, RESET_VEHICLE_CURRENT } from '../actions/types'

const INITIAL_STATE = {
  makes: [],
  models: [],
  years: [],
  fuels: [],
  engines: [],
  power: [],
  current: {
    _id: null,
    type: '',
    make: '',
    range: '',
    fuel: '',
    model: '',
    reg: '',
    cylinder: '',
    engine: '',
    valve: '',
    transmission: '',
    gears: '',
    year: '',
    ecuType: '',
    manual: null,
    bhp: {
      stock: null,
      stageOneBasic: null,
      stageOnePerf: null,
      stageTwo: null
    },
    torque: {
      stock: null,
      stageOneBasic: null,
      stageOnePerf: null,
      stageTwo: null
    }
  },
  loading: false,
  error: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_VEHICLE:
      return {
        ...state,
        ...action.payload
      }

    case RESET_VEHICLE_CURRENT:
      return {
        ...state,
        current: {
          ...INITIAL_STATE.current
        }
      }

    default:
      return state
  }
}
