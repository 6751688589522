import React, { Component } from 'react'
import { connect } from 'react-redux'

import { fetchDynoList } from '../actions/dyno'
import MainTemplate from '../templates/Main'
import DynoOverview from '../components/DynoOverview'

class DynoListScreen extends Component {
  componentDidMount() {
    this.props.fetchDynoList()
  }

  render() {
    const { dyno } = this.props

    if (dyno.loading) {
      return (
        <MainTemplate>
          <h1>Dynos</h1>
          <div className="row">
            <div className="col-12 mx-auto">
              <span className="loading p-6" />
            </div>
          </div>
        </MainTemplate>
      )
    }

    if (dyno.list.length === 0) {
      return (
        <MainTemplate>
          <h1>Dynos</h1>
          <div>
            <p className="text-muted">
              Dynos that you create will appear here.
            </p>
            <p className="text-muted">
              Click the green "New dyno" button above to create your first dyno.
            </p>
          </div>
        </MainTemplate>
      )
    }

    return (
      <MainTemplate>
        <h1>Dynos</h1>
        <div className="row row-cards row-deck" style={{ maxWidth: '100vw' }}>
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table
                  className="
                  table
                  table-vcenter
                  text-nowrap
                  card-table
                  "
                >
                  <thead>
                    <tr>
                      <th>Reg</th>
                      <th>Make/Model</th>
                      <th>Remap</th>
                      <th>Power</th>
                      <th>Torque</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {dyno.list.map(dyno => (
                      <DynoOverview dyno={dyno} key={dyno._id} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </MainTemplate>
    )
  }
}

const mapStateToProps = ({ dyno }) => {
  return { dyno }
}

export default connect(
  mapStateToProps,
  { fetchDynoList }
)(DynoListScreen)
