/* global window, FormData */
import { PublicAPI, PrivateAPI, StorageAPI } from '../utils/api'
import { UPDATE_AUTH, LOGOUT_AUTH } from './types'
import { GCS_BUCKET_NAME, GCS_CLIENT_EMAIL } from '../config'

const authErr = 'Incorrect username or password'
const accErr = 'No account exists with that email'
const passwordErr = 'Incorrect password'
const conflictErr = 'An account already exists with that email'
const logoErr = 'Error uploading logo'
const tokenErr = 'Invalid reset password link'
const subErr = 'Unable to subscribe to plan'
const serverErr = 'An unexpected error occurred'
const connErr = 'Unable to connect'

const publicAPI = PublicAPI()
const privateAPI = PrivateAPI()
const storageAPI = StorageAPI()

const logoutUser = () => {
  window.localStorage.removeItem('auth')
  return { type: LOGOUT_AUTH }
}

export const updateAuth = values => {
  return {
    type: UPDATE_AUTH,
    payload: values
  }
}

export const refreshAuth = () => async dispatch => {
  try {
    dispatch({ type: UPDATE_AUTH, payload: { loading: true } })
    const res = await privateAPI.get('/user')
    dispatch({
      type: UPDATE_AUTH,
      payload: {
        ...res.data.user,
        auth: true,
        loading: false,
        error: null
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response && err.response.status < 500) {
      logoutUser()
    } else if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_AUTH, payload })
  }
}

export const loginUser = ({ email, password }) => async dispatch => {
  try {
    dispatch({ type: UPDATE_AUTH, payload: { loading: true } })
    const res = await publicAPI.post('/login', { email, password })
    window.localStorage.setItem('auth', res.data.token)
    dispatch({
      type: UPDATE_AUTH,
      payload: {
        ...res.data.user,
        auth: true,
        loading: false,
        error: null
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response && err.response.status === 404) {
      payload.error = accErr
    } else if (err.response && err.response.status === 403) {
      payload.error = passwordErr
    } else if (err.response && err.response.stauts < 500) {
      payload.error = authErr
    } else if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_AUTH, payload })
  }
}

export const uploadLogo = async ({ logo }) => {
  const res = await privateAPI.get('/user/upload/png')
  const { policy, filename } = res.data
  const formData = new FormData()
  formData.append('key', filename)
  formData.append('acl', 'public-read')
  formData.append('bucket', GCS_BUCKET_NAME)
  formData.append('Content-Type', 'image/png')
  formData.append('GoogleAccessId', GCS_CLIENT_EMAIL)
  formData.append('policy', policy.base64)
  formData.append('signature', policy.signature)
  formData.append('file', logo)
  await storageAPI({ method: 'post', url: '/', data: formData })
  return filename
}

export const signupUser = values => async dispatch => {
  try {
    dispatch({ type: UPDATE_AUTH, payload: { loading: true } })

    const { businessLogo } = values
    const body = {
      email: values.email,
      businessName: values.businessName,
      password: values.password,
      settings: {
        preferredPower: values.preferredPower,
        preferredTorque: values.preferredTorque
      }
    }

    let res = await publicAPI.post('/user', body)

    window.localStorage.setItem('auth', res.data.token)

    if (businessLogo) {
      try {
        var logoKey = await uploadLogo({ logo: businessLogo })
      } catch (err) {
        const payload = { loading: false, error: logoErr }
        return dispatch({ type: UPDATE_AUTH, payload })
      }
      res = await privateAPI.patch('/user', { logoKey })
    }

    dispatch({
      type: UPDATE_AUTH,
      payload: {
        ...res.data.user,
        loading: false,
        error: null
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response && err.response.status === 409) {
      payload.error = conflictErr
    } else if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_AUTH, payload })
  }
}

export const sendResetPasswordEmail = ({ email }) => async dispatch => {
  try {
    dispatch({ type: UPDATE_AUTH, payload: { loading: true } })
    await publicAPI.post('/reset-password', { email })
    dispatch({
      type: UPDATE_AUTH,
      resetPasswordEmailSent: true,
      loading: false,
      error: null
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response && err.response.status < 500) {
      payload.error = accErr
    } else if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_AUTH, payload })
  }
}

export const checkNewPasswordToken = ({ token }) => async dispatch => {
  try {
    dispatch({ type: UPDATE_AUTH, payload: { loading: true } })
    await publicAPI.post('/new-password-token', { token })
    dispatch({
      type: UPDATE_AUTH,
      payload: {
        resetPasswordToken: token,
        loading: false,
        error: null
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response && err.response.status < 500) {
      payload.error = tokenErr
    } else if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_AUTH, payload })
  }
}

export const saveNewPassword = ({ password, token }) => async dispatch => {
  try {
    dispatch({ type: UPDATE_AUTH, payload: { loading: false } })
    await publicAPI.post('/new-password', { password, token })
    dispatch({
      type: UPDATE_AUTH,
      payload: {
        token: null,
        success: 'Password successfully reset',
        loading: false,
        error: null
      }
    })
  } catch (err) {
    const payload = { loading: false, token: null }

    if (err.response && err.response.status < 500) {
      payload.error = tokenErr
    } else if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_AUTH, payload })
  }
}

export const getUserBillingAuth = ({ plan }) => async dispatch => {
  try {
    dispatch({ type: UPDATE_AUTH, payload: { loading: true, error: null } })
    const res = await privateAPI.get(`/billing/${plan}/auth`)
    dispatch({
      type: UPDATE_AUTH,
      payload: {
        billingAuthToken: res.data.billingAuthToken,
        loading: false,
        error: null
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_AUTH, payload })
  }
}

export const subscribeUserToPlan = ({
  plan,
  paymentMethod
}) => async dispatch => {
  try {
    dispatch({ type: UPDATE_AUTH, payload: { loading: true, error: null } })
    const res = await privateAPI.post(`/billing/${plan}/subscribe`, {
      paymentMethod
    })
    dispatch({
      type: UPDATE_AUTH,
      payload: {
        ...res.data.user,
        loading: false,
        error: null
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_AUTH, payload })
  }
}

export const updateSettings = body => async dispatch => {
  try {
    dispatch({ type: UPDATE_AUTH, payload: { loading: true } })
    const res = await privateAPI.put('/user/settings', body)
    dispatch({
      type: UPDATE_AUTH,
      payload: {
        settings: res.data.user.settings,
        loading: false
      }
    })
  } catch (err) {
    const payload = { loading: false }

    if (err.response || err.request) {
      payload.error = serverErr
    } else {
      payload.error = connErr
    }

    dispatch({ type: UPDATE_AUTH, payload })
  }
}

export { logoutUser }
