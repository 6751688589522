import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Header from '../components/Header'

const MainTemplate = props => {
  if (!props.auth._id) {
    return <Redirect to="/login" />
  }

  if (!props.auth.approved) {
    return <Redirect to="/awaiting-approval" />
  }

  return (
    <div className="page">
      <div className="page-main">
        <Header />
        <div className="container py-6">{props.children}</div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  return { auth }
}

export default connect(
  mapStateToProps,
  null
)(MainTemplate)
