import React from 'react'

import pdfToJpg from '../utils/pdf-jpg'

const DynoOverview = ({ dyno }) => {
  const { vehicle, remap, pdfKey, powerUnit, torqueUnit, _id } = dyno

  const remaps = {
    stageOneBasic: {
      name: 'Stage One Basic',
      border: '8px solid #2188ff'
    },
    stageOnePerf: {
      name: 'Stage One Performance',
      border: '8px solid #cd201f'
    },
    stageTwo: {
      name: 'Stage Two',
      border: '8px dashed black'
    }
  }

  const units = {
    bhp: 'BHP',
    kw: 'Kw',
    ps: 'PS',
    nm: 'Nm',
    ibft: 'Ib/ft'
  }

  return (
    <tr style={{ borderLeft: remaps[remap].border }}>
      <td>{vehicle.reg || 'N/A'}</td>
      <td>
        <div>{vehicle.make}</div>
        <div className="small text-muted">{vehicle.model}</div>
      </td>
      <td>
        <div>{remaps[remap].name}</div>
        <div className="small text-muted">{vehicle.fuel}</div>
      </td>
      <td>
        <div>
          Tuned: {vehicle.power[powerUnit][remap]} {units[powerUnit]}
        </div>
        <div className="small text-muted">
          Before: {vehicle.power[powerUnit].stock} {units[powerUnit]}
        </div>
      </td>
      <td>
        <div>
          Tuned: {vehicle.torque[torqueUnit][remap]} {units[torqueUnit]}
        </div>
        <div className="small text-muted">
          Before: {vehicle.torque[torqueUnit].stock} {units[torqueUnit]}
        </div>
      </td>
      <td className="text-right">
        <a
          className="btn btn-secondary btn-sm mr-2"
          href={pdfKey}
          download={`${pdfKey}.pdf`}
        >
          Download PDF
        </a>
        <button
          type="button"
          onClick={() => pdfToJpg(pdfKey, _id)}
          className="btn btn-secondary btn-sm"
        >
          Download JPG
        </button>
      </td>
    </tr>
  )
}

export default DynoOverview
