import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Header from '../components/Header'

const SiteTemplate = props => {
  if (props.auth._id) {
    return <Redirect to="/dynos" />
  }

  if (props.auth.approved === false) {
    return <Redirect to="/awaiting-approval" />
  }

  return (
    <div className="page">
      <div className="page-main">
        <Header type="site" />
        {props.children}
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  return { auth }
}

export default connect(
  mapStateToProps,
  null
)(SiteTemplate)
