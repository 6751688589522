import React, { Component } from 'react'
import { connect } from 'react-redux'

import { updateVehicle, resetVehicle } from '../actions/vehicle'
import MainTemplate from '../templates/Main'
import Alert from '../components/common/Alert'
import RegLookup from '../components/RegLookup'
import RegResults from '../components/RegResults'
import ManualLookup from '../components/ManualLookup'
import ManualResults from '../components/ManualResults'
import GenerateDynoForm from '../components/GenerateDynoForm'
import DynoDownload from '../components/DynoDownload'

class NewDynoScreen extends Component {
  componentDidMount() {
    const { status } = this.props.auth

    // Users who's account isn't current cannot create new dynos
    if (status !== 'active' && status !== 'trialing') {
      this.props.history.push('/dynos')
    }
  }

  componentWillUnmount() {
    this.props.resetVehicle()
  }

  render() {
    const { vehicle, dyno } = this.props

    return (
      <MainTemplate>
        <h1>New dyno</h1>
        {vehicle.error && (
          <Alert
            error={vehicle.error}
            onDismissError={() => this.props.updateVehicle({ error: null })}
          />
        )}
        <div className="row">
          <div className="col-12 col-lg-5">
            <div>
              <h2 className="h4">Registration lookup</h2>
              <RegLookup />
            </div>
            <div className="mt-6">
              <h2 className="h4">Manual lookup</h2>
              <ManualLookup />
            </div>
          </div>
          <div className="col-12 col-lg ml-lg-4">
            {vehicle.current._id && (
              <>
                {vehicle.current.manual ? <ManualResults /> : <RegResults />}
                <GenerateDynoForm />
                {dyno.current.pdfKey && <DynoDownload />}
              </>
            )}
          </div>
        </div>
      </MainTemplate>
    )
  }
}

const mapStateToProps = ({ auth, vehicle, dyno }) => ({ auth, vehicle, dyno })

export default connect(
  mapStateToProps,
  { updateVehicle, resetVehicle }
)(NewDynoScreen)
